var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('exception-page', {
    style: "min-height: ".concat(_vm.minHeight),
    attrs: {
      "home-route": "/",
      "type": "403"
    }
  });
};

var staticRenderFns = [];
export { render, staticRenderFns };